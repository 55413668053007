/* Style for EmployeeList and GuestList components */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #2d77a1; /* Set your desired background color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

table {
  width: 80%;
  border-collapse: collapse;
  border: 1px solid #e0e0e0;
  margin-top: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: 600;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

tr:hover {
  background-color: #e0e0e0;
  transition: background-color 0.2s ease-in-out;
}

button {
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive styling */
@media screen and (max-width: 600px) {
  h2 {
    font-size: 24px;
  }
  
  th, td {
    padding: 8px;
  }
  
  button {
    padding: 6px 10px;
  }
}
