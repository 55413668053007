.check-in-out-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2d77a1; /* Set your desired background color */
    height: 100vh;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  input, select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  