.home-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  margin-top: 10px;
}
.button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.button-container button {
  margin: 5px;
  width: 250px; 
  height: 40px; 
  background-color: rgb(40, 40, 236);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
