.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to bottom, #f0f0f0, #e0e0e0);
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border: 2px solid #151111;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    width: 350px;
    margin: 0 auto;
    transition: box-shadow 0.3s ease;
    &:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    }
  }
  
  input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    &:focus {
      border-color: #007bff;
      box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.3);
    }
  }
  
  button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  