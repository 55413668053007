.add-guest-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #2d77a1; /* Set your desired background color */
  }
  
  .form-group {
    margin: 10px 0;
  }
  
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .image-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
  }
  