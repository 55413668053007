
.history-container {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  background-color: #2d77a1; 
  justify-content: center;
  /* height: 80vh; */
  padding: 200px;
}

.history-section {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.history-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.history-table th,
.history-table td {
  border: 1px solid #e0e0e0;
  padding: 12px;
  text-align: center;
}

.history-table th {
  background-color: #f7f7f7;
  font-weight: 600;
}

.history-table tbody tr:nth-child(even) {
  background-color: #f7f7f7;
}

/* Add hover effect to rows */
.history-table tbody tr:hover {
  background-color: #e0e0e0;
  transition: background-color 0.2s;
}

/* Style table headers for smaller screens */
@media (max-width: 768px) {
  .history-table th,
  .history-table td {
    padding: 8px;
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  .history-table th {
    position: sticky;
    top: 0;
    background-color: #f7f7f7;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
